<!-- 背景页面 -->
<template>
  <div class="w100 h100 oHide container">
    <div v-if="list.length == 0" class="bgItem w100 h100">
      <img class="w100 h100" src="http://localhost:12008/static/images/theme.png" alt="" />
    </div>
    <div class="bgItem w100 h100" :data-bgidx="idx" :style="`z-index:${idx == showIndex ? 999 : idx}`"
      v-show="idx == nextIdx || idx == preIndex || idx == showIndex" :class="[
      {
        now: idx == showIndex,
        ready: idx == readyIndex,
        left: idx < showIndex,
        right: idx > showIndex,
        top: idx < showIndex,
        bottom: idx > showIndex,
      },
      effect,
    ]" v-for="(item, idx) in list" :key="item.id">
      <!-- 组合 -->
      <div :class="'w100 h100 combine ' + item.combineName" v-if="item.type == 'combine' &&
      (idx == nextIdx || idx == preIndex || idx == showIndex)
      ">
        <div class="combineItem" v-for="(sub, sidx) in item.url" :data-no="sidx" :key="sub.url">
          <!-- 图片 -->
          <img class="w100 h100" v-if="sub.type == 'img'" :src="'http://localhost:12008/static/images/' + sub.source"
            alt="" />
          <!-- 视频 -->
          <video class="w100 h100 video" :class="{ [`video_${item.id}`]: true }" v-if="sub.type == 'video'"
            :src="'http://localhost:12008/static/images/' + sub.source" @ended="videoEnded(idx)" :muted="mute"></video>
          <!-- 网页 -->
          <iframe class="w100 h100" style="border: none" :src="'' + sub.url" v-if="sub.type == 'url'"></iframe>
        </div>
      </div>
      <!-- 图片 -->
      <img class="w100 h100" v-if="item.type == 'img' && (idx == nextIdx || idx == preIndex || idx == showIndex)
      " :src="'http://localhost:12008/static/images/' + item.url[0].source" alt="" />
      <!-- 视频 -->
      <video class="w100 h100 video" :class="{ [`video_${item.id}`]: true }" v-if="item.type == 'video' && (idx == nextIdx || idx == preIndex || idx == showIndex)
      " :src="'http://localhost:12008/static/images/' + item.url[0].source" @ended="videoEnded(idx)"
        :muted="mute"></video>
      <!-- 网页 -->
      <iframe class="w100 h100" style="border: none" :src="item.url[0].url" v-if="item.type == 'url' && (idx == nextIdx || idx == preIndex || idx == showIndex)
      "></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "backgroundPage",
  data() {
    return {
      //当前使用的特效
      effect: "defaultEffect",
      //特效列表
      effects: [],
      //显示第几个
      showIndex: 0,
      //是否静音
      mute: true,
      //列表
      list: [],
      //下一个显示
      nextIdx: -1,
      //上次显示的
      preIndex: -1,
      //列表是否正在发生变化
      listChange: false,
      //视频加载状态
      videoReadyState: 0,
    };
  },
  methods: {
    //视频播完了
    videoEnded(idx) {
      if (idx == this.showIndex) {
        this.$ws.send("broadcast.broadcastMessage", {
          type: "videoPlayEnded",
          data: "",
        });
      }
    },
    //音量发生变化
    volumeChange(val) {
      var videoList = document.querySelectorAll(".video");
      for (const v of videoList) {
        v.volume = Number(val) / 100;
      }
    },
    //是否静音
    muteChange(val) {
      this.mute = val == 1;
    },
    //特效选择发生变化
    effectChange(val) {
      if (typeof val == "string") {
        this.effects = JSON.parse(val);
      } else {
        this.effects = val;
      }
    },
    //播放列表发生变化
    playListChange(val) {
      this.listChange = true;
      this.nextIdx = -1;
      this.preIndex = -1;
      if (typeof val == "string") {
        this.list = JSON.parse(val);
      } else {
        this.list = val;
      }
      window.bgList = this.list;
      this.$nextTick(() => {
        this.playIdxChange(this.nextIdx == -1 ? this.showIndex : this.nextIdx);
      });

    },
    //刷新背景页面
    refshBackground() {
      location.reload();
    },
    //播放第几张发生变化
    async playIdxChange(val) {

      if (this.effects.length == 0) {
        this.effect = "";
      } else {
        let eidx = Math.floor(Math.random() * this.effects.length);
        this.effect = this.effects[eidx];
      }
      //先把下一个要显示的给显示出来
      this.nextIdx = Number(val);
      await new Promise((resolve) => {
        var nextEL = document.querySelector(`.bgItem[data-bgidx="${val}"]`);
        if (nextEL == null) {
          resolve(true);
          return;
        }
        let task = setInterval(() => {
          if (nextEL.style.display != "none") {
            clearInterval(task);
            resolve(true);
          }
        }, 20);
      });
      this.preIndex = this.showIndex;

      this.$nextTick(async () => {
        this.showIndex = Number(val);
        if (this.list[val] == null) {
          return;
        }

        let vList = document.querySelectorAll(`.bgItem:not(.video_${this.list[val].id}) video`);
        for (let i = 0; i < vList.length; i++) {
          // vList[i].currentTime = 0;
          try {
            vList[i].pause();
          } catch (ex) {
            console.log(ex);
          }
        }
        if (this.list[val].type == "video") {
          await new Promise((resolve) => {
            let task = setInterval(() => {
              // vList = document.querySelectorAll(`.bgItem[data-bgidx="${val}"] video`);
              vList = document.querySelectorAll(`.video_${this.list[val].id}`);
              if (vList.length > 0) {
                clearInterval(task);
                resolve();
              }
            }, 50);
          });

          await new Promise((resolve) => {
            setTimeout(resolve, 100);
          })
          for (let i = 0; i < vList.length; i++) {
            vList[i].currentTime = 0;
            vList[i].play();
          }
        }
      });
      setTimeout(() => {
        this.preIndex = -1;
      }, 2000);

    },
  },
  computed: {
    //准备显示第几个
    readyIndex() {
      let readyIdx = this.showIndex + 1;
      if (readyIdx >= this.list.length) {
        readyIdx = 0;
      }
      return readyIdx;
    },
  },
  mounted() {
    //订阅广播消息
    {
      this.$ws.send("broadcast.subscribe", {});
      this.$ws.subscribe("broadcast.subscribe", async (res) => {
        const r = res.data;
        if (r == null || r.type == null) {
          return;
        }
        if (typeof this[r.type] == "function") {
          this[r.type](r.data);
        }
      });
      //准备就绪
      this.$ws.send("appState.initBackground", {});
    }
  },
  beforeUnmount() { },
};
</script>
<style scoped src="@/assets/css/background.css"></style>
<style scoped src="@/assets/css/effect.css"></style>
<style scoped src="@/assets/css/combine.css"></style>
<style scoped src="@/assets/css/animation.css"></style>
